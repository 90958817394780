<template>
  <div :class="{ 'has-logo': showLogo }" class="left-menu-style">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        @select="selectMenu"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      const roles = window.localStorage.getItem('roles')
      let userRoutes = []
      if (roles && roles.indexOf('ADMIN') !== -1) {
        // 如果是管理员
        userRoutes = this.$router.options.routes
      } else {
        // 如果不是管理员，则剔除管理员的路由
        userRoutes = this.$router.options.routes.filter(function(route, index) {
          return route.name !== 'Manage'
        })
      }
      return userRoutes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  watch: {
    $route: {
      handler(n, o) {
        this.getBreadcrumb()
      },
      immediate: true
    }
  },
  created() {
    this.$store.commit('user/SET_INFO')
  },
  methods: {
    selectMenu(path, pathArr) {
      // let matched=this.$route.matched
    },
    getBreadcrumb() {
      let pathObj = this.$route
      let list = JSON.parse(sessionStorage.getItem('tagList'))
        ? JSON.parse(sessionStorage.getItem('tagList'))
        : []
      if (pathObj.meta && pathObj.meta.title) {
        if (
          !list.some(item => {
            return item.path == pathObj.path
          })
        ) {
          let newsTitle = this.$route.query.newsTitle
          list.push({
            name: newsTitle ? newsTitle : pathObj.meta.title,
            path: pathObj.path
          })
        }
      }

      sessionStorage.setItem('tagList', JSON.stringify(list))
      this.$store.commit('settings/SET_TABS')
    }
  }
}
</script>
<style scoped>
.left-menu-style {
  box-shadow: 3px 0px 9px 0px #909090;
}
</style>
