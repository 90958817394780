<template>
  <div class="total-components">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [String],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.total-components {
  padding: 5px 10px;
  border-bottom: 5px solid #f0f2f5;
  color: #a3a0a0;
  font-weight: 500;
}
</style>
