import request from '@/plugins/axios'

export function authLogin(data) {
  // 获取twitter帐号列表
  return request({
    url: `/auth/restLogin?site=${data.origin}&token=${data.auth}`,
    method: 'get'
  })
}

export function login(params) {
  // 获取twitter帐号列表
  return request({
    url: '/auth/login',
    method: 'post',
    data: params
  })
}

export function logOut() {
  return request({
    url: '/auth/logout',
    method: 'get'
  })
}

export function loginUserInfo() {
  return request({
    url: '/user/getUserInfo',
    method: 'get'
  })
}

export function userPage(page, limit, user) {
  return request({
    url: `/user/${page}/${limit}`,
    params: user,
    method: 'get'
  })
}

export function addUser(user) {
  return request({
    url: '/user/save',
    method: 'post',
    data: user
  })
}

export function updateColumn(id, column, value) {
  return request({
    url: `/user/updateColumn/${id}/${column}/${value}`,
    method: 'put'
  })
}

export function resetPwd(id) {
  return request({
    url: `/user/resetPwd/${id}`,
    method: 'put'
  })
}

export function updateUserInfo() {
  return request({
    url: '/user/updateBatchUserInfo',
    method: 'put'
  })
}
