import pagination from './pagination/Pagination.vue'
import noData from './pagination/NoData.vue'
import totalCom from './pagination/Total.vue'
const components = {
  install: function(Vue) {
    Vue.component('pagination', pagination)
    Vue.component('noData', noData)
    Vue.component('totalCom', totalCom)
  }
}
export default components
