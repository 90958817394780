import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Layout from '../layout/Stage'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/key',
    component: Layout,
    name: 'keyParent',
    children: [
      {
        path: 'words',
        name: 'keywords',
        component: () => import('@/views/Keywords.vue'),
        meta: {
          title: '关键词方案',
          icon: 'el-icon-s-comment'
        }
      }
    ]
  },

  {
    path: '/public',
    component: Layout,
    name: 'sentimentParent',
    children: [
      {
        path: 'sentiment',
        name: 'sentiment',
        component: () => import('@/views/Sentiment.vue'),
        meta: {
          title: '舆情数据',
          icon: 'el-icon-s-data'
        }
      }
    ]
  },

  {
    path: '/sentiment',
    component: Layout,
    name: 'sentimentParent2',
    hidden: true,
    children: [
      {
        path: 'detail/:newsId',
        name: 'sentimentDetail',
        component: () => import('@/views/SentimentDetail.vue'),
        meta: {
          title: '新闻详情',
          icon: 'el-icon-reading'
        }
      }
    ]
  },
  {
    path: '/focus',
    component: Layout,
    name: 'FocusParent',
    children: [
      {
        path: 'on',
        name: 'FocusOn',
        component: () => import('@/views/Focus.vue'),
        meta: {
          title: '重点关注',
          icon: 'el-icon-office-building'
        }
      }
    ]
  },
  {
    path: '/acquisition',
    component: Layout,
    name: 'AcquisitionRange',
    children: [
      {
        path: 'range',
        name: 'range',
        component: () => import('@/views/Range.vue'),
        meta: {
          title: '采集范围',
          icon: 'el-icon-thumb'
        }
      }
    ]
  },

  {
    path: '/authLogin',
    name: 'AuthLogin',
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
