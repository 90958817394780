<template>
  <div class="tab-page">
    <el-tag
      type="info"
      v-for="(item, i) in tabList"
      :key="i"
      size="mini"
      :class="item.path == $route.path ? 'select-tab' : ''"
      @click.prevent="selecFun(item)"
      @close="closeFun(item, i)"
      :closable="tabList.length > 1 ? true : false"
    >
      {{ item.name }}
    </el-tag>
  </div>
</template>

<script>
import '@/styles/variables.scss'
export default {
  computed: {
    tabList() {
      return this.$store.state.settings.tabs
    },
    variables() {
      return variables
    }
  },
  created() {},
  methods: {
    selecFun(row) {
      this.$router.push(row.path)
    },
    closeFun(row, i) {
      if (this.tabList.length > 1) {
        this.tabList.splice(i, 1)
        sessionStorage.setItem('tagList', JSON.stringify(this.tabList))
        this.$store.commit('settings/SET_TABS')
        if (this.$route.path == row.path) {
          if (i > 0) {
            let path = this.tabList[i - 1].path
            this.$router.push(path)
          } else {
            let path = this.tabList[0].path
            this.$router.push(path)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-page {
  width: 100%;
  height: 29px;
  padding: 5px;

  line-height: 23px;
  box-shadow: rgb(180, 180, 180) 0px 0px 2px;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: -4px;
  ::v-deep .el-tag {
    max-width: 150px;

    user-select: none;
    cursor: pointer;
    margin: 0 3px;
    padding: 0 8px;
    padding-right: 20px !important;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    &.el-tag--mini {
      height: 25px;
      line-height: 25px;
    }
    &.el-tag--info {
      background-color: #fff;
    }
  }
  ::v-deep .el-tag__close {
    position: absolute !important;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
  }
  .select-tab {
    background: #3d2a23 !important;
    color: white;
    ::v-deep .el-tag__close {
      color: #ffffff;
    }
  }
}
</style>
